.navbar {
  width: 100%;
  height: 70px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.navbar .content {
  height: 100%;
  max-width: 1700px;
}

.navbar .content .logo {
  margin-left: 38px;
  text-decoration: none;
}

.logoImage {
  height: 35px;
  margin-top: 10px;
}

.logo .content .link {
  text-decoration: none;
  color: inherit;
}

.navbar .content .link {
  text-decoration: none;
  color: inherit;
}
