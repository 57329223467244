.question {
  color: var(--primary);
  text-align: left;
  margin: 0px 30px;
}

.question .title {
  font-size: 68px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 66px;
}

.question .more {
  text-decoration: underline;
}
