.selection-items {
    display: grid;
    gap: 20px;
    grid-template: "a a a";
}

.selection-items .selection-item, .selected-item {
    padding: 20px;
    border: 1px solid;
    border-radius: 5px;
    cursor: pointer;
}

.selection-items .selection-item:hover, .selection-items .selection-item.selected, .selected-item {
    background-color: var(--light-gray);
}