:root {
  --primary: #D24726;
  --secondary: #f65a34;
  --white: #ffffff;
  --gray: #d5d8dd;
  --light-gray: #f5f5f5;
  --dark-gray: #576275;
  --black: black;
  --red: #c1272d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="email"],
input[type="text"],
input[type="number"],
input[type="password"],
input[type="file"],
input[type="date"],
textarea,
select {
  width: 200px;
  padding: 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid var(--black);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
}

select.wider {
  width: 336px;
}

input[type="email"].wide,
input[type="text"].wide,
input[type="number"].wide,
input[type="password"].wide,
input[type="file"].wide,
textarea.wide,
select.wide {
  width: 400px;
}

input[type="email"].w-100,
input[type="text"].w-100,
input[type="number"].w-100,
input[type="password"].w-100,
input[type="file"].w-100,
textarea.w-100,
select.w-100 {
  width: 100%;
}

.sub-question {
  font-size: 23px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: var(--primary);
}

.sub-question.small {
  font-size: 19px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: var(--primary);
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.button-add-container {
  width: 100%;
  overflow: none;
}

.button {
  background-color: var(--primary);
  border-radius: 8px;
  padding: 8px 26px;
  color: var(--white);
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

.button.add {
  height: 20px;
  width: 40px;
  margin: auto 2px auto 5px;
  padding: 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: none;
}

.button.mail {
  width: 90px;
  padding: 8px 8px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: none;
}

.button.alt {
  background-color: var(--dark-gray);
  margin-bottom: 2px;
}

.button:hover {
  background-color: var(--secondary);
}

.button.fixed {
  position: fixed;
  bottom: 50px;
}

.button.fixed.next {
  right: calc(max(100vw - 1600px, 50px) / 2);
}

.button.fixed.back {
  right: calc((max(100vw - 1600px, 50px) / 2) + 120px);
}

.flex {
  display: flex;
}

.flex-end {
  align-items: flex-end;
}

.flex-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.items-baseline {
  align-items: baseline;
}

.m-4 {
  margin: 1rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}
.mr-40 {
  margin-right: 10rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5-neg {
  margin-left: -1.25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}
.mb-auto {
  margin-bottom: auto;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.p-8 {
  padding: 2rem;
}

.pointer {
  cursor: pointer;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.w-100 {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.red {
  color: var(--red);
}

.blue {
  color: var(--primary);
}

.align-items-end {
  align-items: end;
}

.text-justify {
  text-align: justify;
}
.font-bold {
  font-weight: bold;
}

.display-mobile {
  visibility: hidden;
}

@media (max-width: 1000px) {
  html,
  body,
  #root {
    overflow: hidden;
  }

  .display-mobile {
    visibility: visible;
    display: flex;
    position: fixed;
    width: calc(100vw - 40px);
    height: 100vh;
    padding: 20px;
    background-color: var(--white);
    top: 74px;
    left: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
