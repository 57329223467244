.main {
  max-width: 1600px;
  margin: 70px auto;
  padding-left: 50px;
  padding-right: 50px;
}

.main .content {
  margin-top: 50px;
}

.main .content .question-container {
  margin: 40px auto 40px 80px;
  min-width: 1000px;
}
