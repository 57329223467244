.nav.horizontal .nav-item {
  width: 100%;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.nav.horizontal .nav-item .status {
  background-color: var(--gray);
  height: 4px;
  margin-bottom: 10px;
}

.nav.horizontal .nav-item .text {
  color: var(--dark-gray);
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}

.nav.horizontal .nav-item .status.active {
  background-color: var(--primary);
}
